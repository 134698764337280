<template>
    <div>
        <div>
            <Breadcrumb content="勘误管理"/>
        </div>
        <div>
            <el-button-group>
                <el-button @click="refresh" type="primary" icon="el-icon-refresh-left" title="刷新"></el-button>
                <el-button type="warning" @click="$router.push('erattasAdd')">新增勘误</el-button>
            </el-button-group>
        </div>
        <div>
            <el-table
                    :data="tableData"
                    border
                    size="small"
                    stripe
                    style="width: 100%">
                <el-table-column prop="created_at" label="添加时间" width="180"></el-table-column>
                <el-table-column prop="update" label="更新日期"></el-table-column>
                <el-table-column prop="title" label="标题"></el-table-column>
                <el-table-column prop="card_no" label="卡片编号"></el-table-column>
                <el-table-column prop="card_name" label="卡牌名称">
                </el-table-column>
                <el-table-column
                        label="卡牌图片"
                        width="150"
                >
                    <template slot-scope="scope">
                        <el-image
                                style="height: 40px;"
                                :src="scope.row.img"
                                fit="contain"
                                :preview-src-list="[scope.row.img]"
                        >
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column width="180" label="操作">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                type="warning"
                                @click="$router.push({path:'/admin/erattasEdit',query:{info:scope.row}})"
                        >
                            编辑
                        </el-button>
                        <el-button
                                size="mini"
                                type="danger"
                                @click="handleDel(scope.row)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/admin/breadcrumb'
    export default {
        name: "list",
        components:{Breadcrumb},
        data:function () {
            return {
                pageSize:this.$webConfig.pages.pageSize,
                pageSizes:this.$webConfig.pages.pageSizes,
                currentPage:1,
                tableData: [],
                total:0
            }
        },
        methods: {
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.getList();
            },

            handleDel(row){
                this.$confirm("是否确认删除?").then(()=>{
                    this.$axios.post("site/siteEratta/del",{id:row.id})
                        .then((res) => {
                            if (res.data.code == 1000){
                                this.$message.success("删除成功");
                                setTimeout(()=>{
                                    this.getList();
                                },1000)
                            }else{
                                this.$message.error("删除失败");
                            }
                        })
                })

            },
            getList:function () {
                let page = this.currentPage;
                let limit = this.pageSize;
                this.$axios.post("site/siteEratta/getList",{page,limit})
                    .then((res) => {
                        if (res.data.code == 1000){
                            this.tableData = res.data.data.list;
                            this.total = res.data.data.total;
                        }
                    })
            },
            refresh:function () {
                this.getList();
                this.$message.success("列表刷新成功")
            }
        },
        created() {
            this.getList();
        }
    }
</script>

<style scoped>

</style>
